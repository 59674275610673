/**
 * @class BeforeAfterSlider
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './before-after-slider.module.css'

export default class BeforeAfterSlider extends Component {
  static propTypes = {
    before: PropTypes.object.isRequired,
    after: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    defaultProgress: PropTypes.number,
    className: PropTypes.string,
    beforeClassName: PropTypes.string,
    afterClassName: PropTypes.string,
    beforeProps: PropTypes.object,
    afterProps: PropTypes.object
  }

  static defaultProps = {
    defaultProgress: 0.5,
    beforeProps: { },
    afterProps: { }
  }

  state = {
    progress: this.props.defaultProgress
  }

  render() {
    const {
      before,
      after,
      className,
      defaultProgress,
      beforeClassName,
      afterClassName,
      beforeProps,
      afterProps,
      width,
      height,
      ...rest
    } = this.props

    const {
      progress
    } = this.state

    return (
      <div
        className={classNames(styles.container, className)}
        style={{
          width,
          height
        }}
        {...rest}
      >
        <div
          className={styles.afterWrapper}
          style={{
            width: `${100 * progress}%`
          }}
        >
          <div 
            className={classNames(styles.after, afterClassName)} 
            style={{ width }}
            {...afterProps}>
            {after}
          </div>
        </div>
        
        <div
         className={classNames(styles.before, beforeClassName)}
         {...beforeProps}
        >
            {before}
        </div>
        <div
          className={styles.handle}
          style={{
            left: `${100 * progress}%`
          }}
        />

        <div
          className={styles.wrapper}
          ref={this._contentRef}
          onTouchMove={this._onMoveWrapper}
          onMouseMove={this._onMoveWrapper}
        />

        <div
          className={styles.content}
          onTouchMove={this._onMoveContent}
          onMouseMove={this._onMoveContent}
        />

      </div>
    )
  }

  _contentRef = (ref) => {
    this._content = ref
  }

  _onMoveWrapper = (event) => {
    event.preventDefault()
    let { offsetX } = event.nativeEvent
    if (!offsetX) {
      const rect = event.target.getBoundingClientRect()
      offsetX = event.targetTouches ? event.targetTouches[0].pageX - rect.left : 0
    }
    const { width } = this.props
    const progress = Math.max(0, Math.min(1, (offsetX - width / 10) / width))
    this.setState({ progress })
  }

  _onMoveContent = (event) => {
    event.preventDefault()
    let { offsetX } = event.nativeEvent
    if (!offsetX) {
      const rect = event.target.getBoundingClientRect()
      offsetX = event.targetTouches ? event.targetTouches[0].pageX - rect.left : 0;
    }
    const { width } = this.props
    const progress = Math.max(0, Math.min(1, offsetX / width))
    this.setState({ progress })
  }
}