import React from 'react';
import './App.css';

import useWindowDimensions from './components/use-window.dimensions';

import LottieControl from './components/lottie-control/lottie-control';
import  {default as BeforeAfterSliderOwn} from './components/before-after-slider';

function App() {
  const { height, width } = useWindowDimensions();
  let animatedDataBlack = require('./assets/logo_black_landscape.json'); 
  let animatedDataWhite = require('./assets/logo_white_landscape.json');
  
  if (height > width) {
    animatedDataBlack = require('./assets/logo_black_portrait.json');  
    animatedDataWhite = require('./assets/logo_white_portrait.json');
  }

  const afterControl = <LottieControl animationData={animatedDataWhite}/>
  const beforeControl = <LottieControl animationData={animatedDataBlack}/>

  return (
    <div className="App">
      <BeforeAfterSliderOwn
        before={beforeControl}
        after={afterControl}
        width={width ?? 0}
        height={height ?? 0}
      />
    </div>
    //  <LottieControl animationData={animatedDataBlack}/>
  );
}

export default App;
