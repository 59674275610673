import React from 'react'
import Lottie from 'react-lottie';
 
export default class LottieControl extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }
 
  render() {
    const defaultOptions = {
      isClickToPauseDisabled: true,
      loop: true,
      autoplay: true,
      animationData: this.props.animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        progressiveLoad: false,
        hideOnTransparent: true,
        focusable: false
      }
    };
 
    return <Lottie options={defaultOptions}
        isStopped={this.state.isStopped}
        isPaused={this.state.isPaused}/>
  }
}